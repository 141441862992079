import React from 'react'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageTitle from '../components/PageTitle'
import SEO from '../components/SEO'
import CardList from '../components/CardList'
import { Link } from 'gatsby'
import styled from '@emotion/styled'
import { graphql } from 'gatsby'

const groupByYear = function(data) {
  // `data` is an array of objects, `key` is the key (or property accessor) to group by
  // reduce runs this anonymous function on each element of `data` (the `item` parameter,
  // returning the `storage` parameter at the end
  return data.reduce(function(storage, { node }) {
    // get the first instance of the key by which we're grouping
    const publishDate = new Date(node.publishDate)
    var group = publishDate.getFullYear()

    // set `storage` for this instance of group to the outer scope (if not empty) or initialize it
    storage[group] = storage[group] || []

    // add this item to its group within `storage`
    storage[group].push(node)

    // return the updated storage to the reduce function, which will then loop through the next
    return storage
  }, {}) // {} is the initial value of the storage
}

const YearGroup = styled.div`
  // max-width: ${props => props.theme.sizes.maxWidthCentered};
  // margin: 0 auto;
`

const Year = styled.h2`
  line-height: 1;
  font-size: 1.5em;
  font-weight: 500;
  margin: 1em 0 0.5em 0;
`

const PostLink = styled(Link)`
  color: black;
  font-size: 1em;
  text-decoration: none;
  
  &:hover {
    border-bottom: 1px dashed #111111;
  }
`

const YearPosts = styled.ul`
  line-height: 1.8;
  margin: 0 0 0 1.5em;
  // list-style: square;
`

const Archives = ({ data }) => {
  const posts = data.allContentfulPost.edges
  const years = groupByYear(posts)
  posts.forEach(({ node }) => {
    const publishDate = new Date(node.publishDate)
    const rawMonth = publishDate.getMonth() + 1
    const rawDay = publishDate.getDate()
    const month = rawMonth.toString().length < 2 ? '0' + rawMonth : rawMonth
    const day = rawDay.toString().length < 2 ? '0' + rawDay : rawDay
    node.displayDate = month + '.' + day
  })

  return (
    <Layout>
      <SEO title="Archive" description="List of all articles." />
      <Container>
        <PageTitle>Archive</PageTitle>
        {Object.keys(years)
          .sort()
          .reverse()
          .map((year, item) => (
            <YearGroup>
              <Year>{year}</Year>
              <YearPosts>
                {years[year].map(post => (
                  <li>
                    <PostLink to={`/${post.slug}`}>
                      {post.displayDate}: {post.title}
                    </PostLink>
                  </li>
                ))}
              </YearPosts>
            </YearGroup>
          ))}
      </Container>
    </Layout>
  )
}

export default Archives

export const query = graphql`
  query {
    allContentfulPost(sort: { fields: [publishDate], order: DESC }) {
      edges {
        node {
          title
          id
          slug
          publishDate
        }
      }
    }
  }
`

//  publishDate(formatString: "MMMM DD, YYYY")
